/* ReportButton.css */

/************* Button design *************/
.report-button__container {
    display: flex;
    gap: 10px;
}

.report-button__trigger {
    background-color: #f1f1f1;
    border: none;
    border-radius: 6px;
    width: 90px;
    min-height: 35px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Shadow border */
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    font-size: 1.1rem;
}

.report-button__trigger:hover {
    background-color: #cfcfcf;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Increased shadow on hover */
}

.report-button__trigger.reported {
    background-color: rgb(186, 186, 186);
    color: rgb(78, 82, 87);
    cursor: not-allowed;
    width: 130px;
}

/* Cancel hover when the button is already validated*/
.report-button__trigger.reported.disabled:hover {
    transform: none; /* Cancel hover effect */
    cursor: not-allowed; /* Change cursor to indicate it's not clickable */
}

/************* Modal design *************/
.report-button__modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.report-button__modal-content {
    background: white;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
    width: 90%;
    max-width: 300px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.report-button__modal-content--expanded {
    max-width: 600px;
}

.report-button__modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 20px;
}

.report-button__title {
    font-size: 1.5em;
    margin: 0;
    flex-grow: 1;
    text-align: left;
}

.report-button__close {
    width: 0.8em;
    height: 0.8em;
    cursor: pointer;
    margin-left: 10px;
    transition: opacity 0.3s ease;
    opacity: 0.6;
}

.report-button__close:hover {
    opacity: 1;
}

.report-button__options {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.report-button__option {
    padding: 12px;
    background-color: #f1f1f1;
    border: none;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Shadow border */
    transition: background-color 0.3s, box-shadow 0.3s;
}

.report-button__option:hover {
    background-color: #cfcfcf;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Increased shadow on hover */
}

.report-button__textarea {
    width: 100%;
    height: 200px;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #ccc;
}

.report-button__error-message {
    color: red;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.report-button__trigger.shake {
    animation: shake 0.5s;
}

@keyframes shake {
    0%, 100% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-5px);
    }
    50% {
        transform: translateX(5px);
    }
    75% {
        transform: translateX(-5px);
    }
}

@media (max-width: 360px) {
    .report-button__modal-content {
        max-width: 250px;
    }
}

@media (min-width: 700px) {
    .report-button__trigger {
        width: 120px;
        height: 40px;
        font-size: 1.2rem;
    }
    .report-button__modal-content {
        min-width: 350px;
    }
}