.alert-popup {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20; /* Ensure it's above other content */
    padding: 10px 20px;
    border-radius: 0 0 15px 15px; /* Rounded corners at the bottom */
    color: white;
    cursor: pointer;
    animation: slideDown 0.5s ease-out forwards;
    max-width: 600px; /* Maximum width */
    width: calc(100% - 80px); /* Full width minus margins */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.alert-popup--informative {
    background-color: lightgrey;
}

.alert-popup--success {
    background-color: #51A704;
}

.alert-popup--error {
    background-color: red;
}

/* Animation for sliding down */
@keyframes slideDown {
    0% {
        top: -50px;
    }
    100% {
        top: 0;
    }
}