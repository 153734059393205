/******** Picture upload formatting ********/

.image-drop-zone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border-width: 2px;
  border-radius: 2px;
  height: 150px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  width: 100%;
  margin: auto;
  margin-bottom: 20px;
  border-radius: 15px;
}

.image-drop-zone__delete-image {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: transparent; /* Transparent background */
  border: none;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.image-drop-zone__delete-image img {
  width: 100%;
  height: 100%;
  opacity: 0.6;
}

.image-drop-zone__image-preview {
  position: relative;
  display: inline-block;
}

.image-drop-zone__preview-img {
  max-width: 150px;
  max-height: 150px;
}

/********* Design of color and error message *********/

.image-drop-zone--focused {
  border-color: #2196f3;
}

.image-drop-zone--accept {
  border-color: #00e676;
}

.image-drop-zone--reject {
  border-color: #ff1744;
}

.image-drop-zone__container {
  width: 100%;
}

.image-drop-zone__info {
  font-style: italic;
}

.image-drop-zone__error-message-box {
  color: red;
  margin-bottom: 10px;
}

.image-drop-zone__error-message-box.visible {
  display: block;
}