/* Header.css */

/************* Header form *************/
.header {
    position: fixed; /* Ensure the header stays fixed at the top */
    top: 0;
    width: 100%;
    background-color: var(--app-red);
    padding: 0;
    display: flex;
    align-items: center;
    margin-top: 0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: visible;
    height: 60px;
    z-index: 4; /* Ensure the header stays above other content */
    transition: top 0.3s; /* Smooth transition */
}

.header--hidden {
    top: -60px; /* Hide the header */
}

.header--visible {
    top: 0; /* Show the header */
}

.header__logo img {
    height: 35px;
    width: auto;
    margin-left: 10px;
}

.header__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px;
    position: relative;
}

.header__icon {
    height: 20px;
    width: auto;
    margin-right: 0.2rem;
}
/*______________________________*/


/************* Search form *************/
.header__search-form {
    flex-grow: 1;
    margin: 0 5vw;
    max-width: calc(100% - 180px); /*Necessary to ensure on small screen the search bar does not take all the space instead of the menu button*/
}

.header__search-input {
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-left: 2rem;
    border: none;
    font-size: 1rem;
    border-radius: 40px;
    height: 40px;
    background-image: url('../assets/global-icons/search.svg'); 
    background-repeat: no-repeat;
    background-size: 1rem;
    background-position: 10px center;
}
/*______________________________*/


/************* Format all buttons *************/
.header__login-button,
.header__logout-button,
.header__new-deal-button--bigScreen,
.header__menu-button {
    height: 40px;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 100px;
    transition: background-color 0.3s ease;
    margin-right: 0.7rem;
    overflow: hidden;
}

/*______________________________*/

/* All button - except connected button */
.header__login-button,
.header__new-deal-button--bigScreen,
.header__menu-button {
    background-color: var(--app-yellow);
    padding: 0.7rem;
    color: var(--app-red);
    font-weight: bold;
    font-size: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.header__menu-button {
    width: 40px;  /* Ensure it's a perfect square */
    padding: 0;
    align-items: center;
    justify-content: center;
}
.header__menu-button img {
    margin-right: 0;
    height: 25px; /* Increase the size of the icon */
    width: 25px;  /* Ensure the icon remains square */
}

.header__login-button:hover,
.header__new-deal-button--bigScreen:hover,
.header__menu-button:hover {
    background-color: var(--app-yellow-hover);
}

/* Hide connect button on small screens */
.header__connect-bigScreen {
    display: none;
}

/* Connected button */
.header__logout-button {
    background-color: #ea417a;
    border: 1px solid white;
    width: 40px;
    font-family: Arial, sans-serif;
    color: white;
    font-size: 1.2rem;
}

.header__logout-button:hover {
    background-color: #cb3869;
}

.header__login-button .header__icon {
    margin-right: 0; /* Center icon without extra spacing */
}
.header__login-button span {
    display: none; /* Hide the text part of the button by default */
}
.header__login-button {
    width: 40px;
    padding: 0;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
}

/************* Format all buttons *************/
.header__new-deal-button--bigScreen {
    display: none;
}
.header__new-deal-button--smallScreen {
    position: fixed;
    align-items: center;
    justify-content: center;
    left: 5vw;
    bottom: 5vw;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    background-color: var(--app-red);
    border: none;
    cursor: pointer;
    display: flex;
    z-index: 3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}
.header__newDeal-icon--smallScreen {
    height: 50%;
    width: auto;
    filter: invert(100%);
}
/*______________________________*/

@media (max-width: 400px) {
    .header__search-form {
        margin: 0 3vw;
    }
    .header__logo img {
        height: 30px;
    }
}


@media (min-width: 700px) {
    .header__new-deal-button--bigScreen {
        display: flex;
    }
    .header__new-deal-button--smallScreen {
        display: none;
    }
    .header__logo img {
        height: 45px;
    }
    .header__connect-bigScreen {
        display: inline-flex;
    }
    .header__login-button .header__icon {
        margin-right: 0.2rem; /* Add spacing for the icon */
    }
    .header__login-button span {
        display: inline; /* Show the text part of the button on larger screens */
    }
    .header__login-button {
        width: auto;
        padding: 0.7rem;
    }
}

@media (min-width: 1040px) {
    .header__search-form {
        flex-grow: 1;
        margin: 0 120px;
    }
    .header--hidden {
        top: 0px; /* The header should be always displayed on computer */
    }

}