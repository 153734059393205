/* LoginModal.css */

.login-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.login-modal__content {
    background: white;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
    width: 90%;
    max-width: 250px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow for better visibility */
}

.login-modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 20px; /* Separate header from content */
}

.login-modal__title {
    font-size: 1.5em;
    margin: 0;
    flex-grow: 1;
    text-align: left; /* Align text to the left */
}

.login-modal__close {
    width: 0.8em; /* Adjust size of the close icon */
    height: 0.8em; /* Adjust size of the close icon */
    cursor: pointer;
    margin-left: 10px;
    transition: opacity 0.3s ease;
    opacity: 0.6; /* Change opacity on hover */
}

.login-modal__close:hover {
    opacity: 1; /* Change opacity on hover */
}

.login-modal__auth-buttons {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Space between buttons */
}

.login-modal__auth-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 6px;
    transition: background-color 0.3s ease;
}

.login-modal__auth-icon {
    width: 24px; /* Adjust size for better appearance */
    height: 24px;
    margin-right: 12px; /* Space between icon and text */
}

.login-modal__auth-button--google {
    background-color: #db4437;
    color: white;
}

.login-modal__auth-button--google:hover {
    background-color: #a33225;
}

.login-modal__auth-button--facebook {
    background-color: #4267B2;
    color: white;
}

.login-modal__auth-button--facebook:hover {
    background-color: #324c81;
}

.login-modal__auth-button--zalo {
    background-color: #3694ff; /* Zalo blue */
    color: white;
}

.login-modal__auth-button--zalo:hover {
    background-color: #005fbf; /* Darker Zalo blue on hover */
}

/*********** Message terms and conditions ***********/

.login-modal__terms {
    margin-top: 20px;
    font-size: 0.9em;
    color: #666;
    text-align: center;
}

.login-modal__terms a {
    color: var(--app-yellow);
    text-decoration: none;
    font-weight: bold;
}

.login-modal__terms a:hover {
    text-decoration: underline;
}

@media (min-width: 400px) {
    .login-modal__content {
        max-width: 300px;
    }
}

@media (min-width: 700px) {
    .login-modal__auth-icon {
        width: 24px; /* Adjust size for better appearance */
        height: 24px;
        margin-right: 20px; /* Space between icon and text */
    }
}