/* AccountModal.css */

/* Modal positioned below the account button */
.account-modal {
    position: absolute;
    z-index: 10;
    top: 55px; /* Position just below the button */
    right: 5px; /* Align to the right of the container */
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    width: 200px; /* Make the modal wider on large screens */
}

/* Arrow connecting the modal to the button */
.account-modal__arrow {
    position: absolute;
    top: -10px; /* Position the arrow above the modal to connect with the button */
    right: 6px; /* Adjust arrow position relative to the modal width */
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
}

/* Modal content styling */
.account-modal__content {
    background: white;
    border-radius: 10px;
    text-align: center;
}

/* Prevent scrolling when modal is open */
.account-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

/************* Language button section *************/
.account-modal__header {
    display: flex;
    justify-content: space-between; /* Space evenly between the boxes */
    padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
}

.account-modal__lang-container {
    flex: 1;
    display: flex;
    justify-content: center; /* Center the button within its box */
    margin: 0 5px; /* Add space between the two language containers */
}

.account-modal__lang-box {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    border: none;
    height: 30px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 4px 10px 4px 4px;
    border-radius: 100px;
}

.account-modal__lang-box.selected {
    background-color: #ddd;
    color: white; /* Make the text white to contrast the background */
}

.account-modal__lang-box.selected:hover {
    background-color: #ddd;
}

.account-modal__lang-box span {
    margin-left: 10px;
    font-size: 1rem;
    color: #000;
}

.account-modal__lang-box:hover {
    background-color: #ddd;
}

.account-modal__lang-icon {
    width: 22px; /* Adjust the size of the icon */
    height: 22px;
    border-radius: 50%; /* Ensure the icon is round */
}

/************* Modal options section *************/
.account-modal__options {
    display: flex;
    flex-direction: column;
}

.account-modal__option {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 0;
    background-color: white;
    color: #4267B2;
    font-weight: bold;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
    border-top: 1px solid #e0e0e0;
    text-decoration: none; /* Remove the underline */
}

.account-modal__option:first-child {
    border-top: none;
}

.account-modal__option:hover {
    background-color: #f0f0f0;
}

.account-modal__icon {
    height: 20px; /* Adjust the size of the icon */
    width: auto;
    margin-right: 0.5rem; /* Add some space between the icon and the text */
    vertical-align: middle; /* Align icon with text */
}

/************* Large screen adjustments *************/
@media (min-width: 1040px) {

}

@media (min-width: 1200px) {  
    .account-modal {
		right: -68px; /* Align with the right edge of the container */
    }
    .account-modal__arrow {
        top: -10px; /* Keep the arrow connecting the modal to the button */
        left: 50%; /* Center it horizontally */
    }
}