/* NewDeal.css */

/******** box formatting ********/

.new-deal__boxes {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #ffffff;
    padding: 35px 25px 25px 25px;
    margin: 10px 4px 10px 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border: 1px solid #ddd;
    align-items: center;
    /*min-height: calc(100vh - 80px);*/
    min-height: calc(100vh - 80px); /* Fallback for browsers that do not support dvh */
    min-height: calc(100dvh - 80px); /* Minimum height to cover full viewport height on supported browsers */
    height: auto; /* Extend height to cover content */
    border-radius: 15px;
    position: relative;
}

/* Cancel all formatting dealForm */
#dealForm {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    flex-grow: 1; /* Allows the form to grow and push the button container down */
}

.new-deal__empty-data {
    color: lightgrey;
    font-style: italic;
}

/* Add animation for entering from bottom */
@keyframes enterFromBottom {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.new-deal__animated {
    animation: enterFromBottom 0.5s ease-in-out;
}

/******** Input formatting ********/
.new-deal__content-container {
    flex-grow: 1;
    align-items: center;
    max-width: 600px;
    width: 100%;
    margin: 40px auto 40px auto; 
    display: flex;
    flex-direction: column;
}

.new-deal__title {
    width: 100%;
    text-align: center;
    font-weight: bold;
}

.new-deal__sub-title {
    font-weight: bold;
}

.new-deal__sub-title--centered {
    display: block;
    text-align: center;
    width: 100%;
}

.new-deal__form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
}

.new-deal__form-container p {
    text-align: center; /* Center align the text horizontally */
    margin: 0 auto; /* Center align the text container */
    max-width: 100%; /* Ensure it doesn't exceed the container's width */
}

.new-deal__field-container {
    flex: 1;
    margin-bottom: 20px;
    width: 100%;
}
.new-deal__sub-field-container {
    flex: 1;
    margin-bottom: 0px;
    width: 100%;
}

.new-deal__boxes input {
    width: 100%;
    padding: 4px 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    max-width: 600px;
}

/******** Input formatting ********/
.new-deal__input-container {
    display: flex;
    align-items: center; /* Center items vertically */
    gap: 0; /* Remove gap and control spacing with padding/margin */
}

.new-deal__icon {
    width: 30px; /* Adjust the size as needed */
    height: auto;
    margin-right: 10px; /* Add padding to separate the border from the icon */
}

.new-deal__sub-input-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* Make the sub-input container take the remaining width */
    padding-left: 10px; /* Add padding to the left */
    border-left: 2px solid #FFCD9F; /* Add right border for the divider */
}

.new-deal__info-box {
    background-color: #f0f0f0;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-top: 2px; /* Space between input and info box */
    opacity: 0;
    transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
    transform: scaleY(0);
    transform-origin: top;
    height: 0; /* Initially set height to 0 */
    overflow: hidden;
}

.new-deal__info-box--visible {
    padding: 10px;
    opacity: 1;
    transform: scaleY(1);
    height: auto; /* Adjust height to auto when visible */
    transition: opacity 0.3s ease-in, transform 0.3s ease-in, height 0.3s ease-in-out;
}

.new-deal__error-message-box {
    background-color: white;
    border-radius: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    transition: opacity 0.3s ease-in-out;
    color: red;
    font-weight: bold;
}


.new-deal__price-container {
    width: 100%;
}

/******** National button and drop list design ********/



.new-deal__national-deal-checkbox {
    display: flex;
    flex-direction: column;
}

.new-deal__national-deal-input {
    height: 20px;
    width: 20px;
    margin-top: 5px;
}

.new-deal__national-deal-checkbox input {
    height: 30px;
    width: 30px;
    background-color: white;
    border: 2px solid #ddd;
    border-radius: 5px;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    position: relative;
    margin-right: auto;
    margin-left: auto;
}

.new-deal__national-deal-checkbox input:checked::after {
    content: '✔';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FF8F26;
    font-size: 25px;
}

.new-deal__national-deal-checkbox--render1 {
    margin: 15px 0 0 0;
}

/********* Error formatting *********/

.new-deal__national-deal-checkbox.new-deal__input--error label,
.new-deal__national-deal-checkbox.new-deal__input--error input {
    color: red; /* Change the text color to red */
    border-color: red; /* Change the border color to red */
}

.new-deal__national-deal-checkbox.new-deal__input--error input:checked::after {
    color: red; /* Change the checkmark color to red */
}

.new-deal__location-container select {
    height: 40px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 0 15px;
}

.new-deal__location-container select:disabled {
    background-color: #f0f0f0;
    color: #ccc;
    border: 1px solid #ddd;
}

.new-deal__location-container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

/******** Description box and counter ********/
.new-deal__description-box-container {
    position: relative;
}

.new-deal__description-box-container p{
    text-align: left;
}

.new-deal__description-counter {
    position: absolute;
    top: -20px; /* Adjust as necessary */
    right: 0;
    font-size: 14px;
    color: #555;
    font-style: italic; /* Make the text italic */
}

/******** Date box formatting ********/

.new-deal__date-time-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}

.new-deal__date-container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.new-deal__date-container input {
    margin-bottom: 20px;
}

/******** Category button formatting ********/

.new-deal__category-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
}

.new-deal__category-buttons button {
    background-color: transparent;
    border: 1px solid #ddd;
    padding: 8px 16px;
    border-radius: 20px;
    max-width: 400px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;
    flex-grow: 1;
    text-align: center;
    color: black;
}

.new-deal__category-buttons button:hover {
    background-color: #FF8F26;
    color: white;
}

.new-deal__category-buttons button.selected {
    background-color: #FF8F26;
    color: white;
}


.new-deal__category-icon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.new-deal__date-container input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    max-width: 300px;
}

.new-deal__field-container--disabled input::placeholder {
    color: #ccc;
    opacity: 1;
}

/******** Sum up formatting ********/

.new-deal__image-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;
}

.new-deal__image-box {
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    border-radius: 15px;
}

.new-deal__image-box img {
    max-width: 100%;
    max-height: 100%;
    object-fit: scale-down;
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-deal__content-container-sumup {
    border: 1px solid lightgrey;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 15px;
}

.new-deal__rules-box {
    border: 1px solid #ccc;
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.new-deal__rules-box ol {
    padding-left: 1.2rem;
}

.new-deal__rules-box a {
    color: var(--app-red);
    text-decoration: underline;
}

/******** Error Box ********/

.new-deal__error-message-box {
    background-color: white;
    border-radius: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    transition: opacity 0.3s ease-in-out;
    color: red;
    font-weight: bold;
    opacity: 1; /* Ensure it starts visible */
}

.new-deal__error-message-box.fade-out {
    opacity: 0;
    transition: opacity 1s ease-in-out; /* Fade-out transition */
}

.new-deal__field-container .new-deal__input--error {
    border: 1px solid red;
}

/******** Button formatting ********/

.new-deal__button-container {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.new-deal__button-container button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FF8F26;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease; /* Transition effect for background color */
    width: 100px;
    outline: none; /* Remove default focus outline */
}

.new-deal__button-container button:last-child {
    margin-left: auto;
}

.new-deal__button-container button:hover,
.new-deal__button-container button:focus {
    background-color: #CE731D;
}

.new-deal__button-container button:active {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transform: translateY(1px);
    background-color: #FF8F26; /* Reset background color on click */
}

.new-deal__button-container button:focus {
    background-color: #FF8F26; /* Ensure background color resets after focus */
}

@media (max-width: 360px) {
    .new-deal__icon {
        width: 25px; /* Adjust the size as needed */
        margin-right: 7px; /* Add padding to separate the border from the icon */
    }
    
    .new-deal__sub-input-container {
        padding-left: 7px; /* Add padding to the left */
    }
}

@media (min-width: 500px) {
    .new-deal__sub-field-container {
        display: flex;
        justify-content: space-between;
        gap: 25px;
    }
}
@media (min-width: 840px) {
    .new-deal__title {
        margin-top: 5vh;
    }
    .new-deal__content-container {
        justify-content: center; /* Center the form container vertically on large screens */
    }

    .new-deal__boxes input {
        padding: 8px;
    }

    .new-deal__sub-field-container {
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }

    .new-deal__field-container--side-by-side {
        display: flex;
        gap: 30px;
    }
    .new-deal__sub-field-container {
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }

    .new-deal__national-location-container {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        width: 100%;
    }
    
    .new-deal__national-deal-checkbox,
    .new-deal__location-container {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    

}