/* HomePage.css */

/* Style for the user greeting */
.home-page__user-greeting {
    margin: 20px 40px;
    font-size: 2.5rem;
    color: var(--app-red);
    line-height: 100%;
}

/* Style for the deal boxes container */
.home-page__deal-boxes {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    justify-content: flex-start;
    margin: 0;
    position: relative;
    /*overflow: hidden;*/
}

/* Style for the deal boxes */
.home-page__deal-box {
    flex: 0 0 auto;
    width: calc(50% - 2px);
    /*border: 2px solid var(--app-red);*/
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    background-color: white;
    text-align: center;
    box-sizing: border-box;
    border-radius: 2.5vw;
    overflow: hidden;
    position: relative;
}

.home-page__deal-box--expired {
    filter: grayscale(80%);
    position: relative;
}

.home-page__expired-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: rgba(128, 128, 128, 0.5);
    border-radius: 0.75vw;
}

.home-page__expired-label {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    text-align: center;
    font-weight: bold;
    z-index: 3;
}

/* Style for the images in the boxes */
.home-page__image-container {
    width: 100%;
    padding-top: 100%;
    position: relative;
    background-color: #ffffff;
    overflow: hidden;
}

/* Style for the views box */
.home-page__views-box {
    display: flex;
    align-items: center;
    background-color: var(--app-yellow-2);
    color: var(--app-red);
    color: rgba(0, 0, 0, 0.8);
    padding: 0px 5px;
    border-radius: 0.75vw;
    position: absolute;
    font-size: 1rem;
    z-index: 1;
}

.home-page__views-box--top-left img,
.home-page__views-box--top-left span,
.home-page__views-box--top-right img,
.home-page__views-box--top-right span,
.home-page__views-box--bottom-left img,
.home-page__views-box--bottom-left span {
    /*Red of the interface color with formula to match exactly the color of icons*/
    filter: invert(28%) sepia(87%) saturate(4313%) hue-rotate(344deg) brightness(77%) contrast(111%);
}
/* ________________________ */


.home-page__views-box--top-left {
    top: 0.75vw;
    left: 0.75vw;
}
.home-page__views-box--top-left img {
    margin-right: 0.75vw;
}

.home-page__views-box--bottom-left {
    bottom: 1vw;
    left: 0.75vw;
}
.home-page__views-box--bottom-left img {
    margin-right: 0.75vw;
}

.home-page__views-box--top-right {
    top: 0.75vw;
    right: 0.75vw;
}
.home-page__views-box--top-right img {
    margin-left: 0.75vw;
}

.home-page__views-box--bottom-right {
    bottom: 1vw;
    right: 0.75vw;
    background-color: transparent;
    z-index: 1;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition */
}
.home-page__views-box--bottom-right img {
    height: 2rem;
}
.home-page__views-box--bottom-right:hover {
    transform: translateY(-4px);
}
.home-page__share-icon {
    filter: drop-shadow(0 0 2px rgba(255, 255, 255, 1)) drop-shadow(0 0 1px rgba(255, 255, 255, 1)) drop-shadow(0 0 3px rgba(255, 255, 255, 1));
}

.home-page__box-icon {
    height: 1rem;
}

/* Style for the image itself */
.home-page__deal-image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: 100%;
    object-fit: contain;
    transform: translate(-50%, -50%);
}

/* Style for the content inside the box, excluding the image */
.home-page__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.3vw 1vw 1vw 1vw;
}

/* Style for the deal link */
.home-page__deal-link {
    text-decoration: none;
    color: inherit;
}

/* Style for the title in the deal box */
.home-page__deal-title {
    font-size: 1.0rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin-bottom: 0;
    color: #333;
    line-height: 1.2;
    text-align: left;
    height: 2.4rem;
}

/**************** Price Container ****************/
/* Price Container */
.home-page__price-container {
    display: flex;
    align-items: baseline;
    width: 100%;
}

/* Regular Price */
.home-page__regular-price,
.home-page__current-price {

}

/* Regular Price */
.home-page__regular-price {
    text-decoration: line-through;
    color: #666;
    font-size: 1.1rem;
    margin-right: 1.5vw;
}

/* Regular Price Currency Symbol */
.home-page__regular-currency-symbol {
    font-size: 0.7rem;
}

/* Current Price in red and larger */
.home-page__current-price {
    color: var(--app-red);
    font-size: 1.5rem;
    font-weight: bold;
}

/* Current Price Currency Symbol */
.home-page__current-currency-symbol {
    font-size: 1.2rem;
}

/*_________________________________*/

/* Interaction Box */
.home-page__interaction-box {
    display: flex;
    width: 100%;
    height: 9vw;
    padding: 0 0.7vw 0.7vw 0.7vw;
}

.home-page__vote-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 1.2vw;
    box-shadow: inset 0 0 0 2px var(--app-red);
    font-size: 1.4rem;
    box-sizing: border-box;
    margin-right: 0.7vw;
}

/* Voting sub-part within the interaction section */
.home-page__vote-section {
    width: 33.33%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--app-red);
    font-weight: bold;
}

/* CSS class for positive vote counts */
.home-page__vote-positive-count {
    color: var(--app-green);
}

/* Voting Buttons */
.home-page__vote-button {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Apply the filter only to the thumb up icon */
.home-page__vote-button.thumb-up img {
    /*light blue*/
   /*filter: invert(88%) sepia(19%) saturate(1666%) hue-rotate(163deg) brightness(103%) contrast(105%);*/
    /*blue*/
    /*filter: invert(60%) sepia(88%) saturate(765%) hue-rotate(175deg) brightness(100%) contrast(102%);*/
    /*filter: invert(22%) sepia(14%) saturate(2297%) hue-rotate(163deg) brightness(86%) contrast(82%);*/
    /*vert*/
    /*filter: invert(82%) sepia(19%) saturate(738%) hue-rotate(51deg) brightness(99%) contrast(89%);*/
    /*vert stronger*/
    /*filter: invert(82%) sepia(14%) saturate(6041%) hue-rotate(73deg) brightness(92%) contrast(78%);*/
    /*even vert stronger*/
    filter: invert(75%) sepia(55%) saturate(7018%) hue-rotate(65deg) brightness(105%) contrast(105%);
}

/* Adjust icon size and alignment if necessary */
.home-page__vote-button img {
    width: 60%;
    height: auto;
}

.home-page__vote-button:hover {
    transform: translateY(-3px); /* Slightly raise the button */
}

.home-page__vote-button.disabled:hover {
    transform: none; /* Cancel hover effect */
    cursor: not-allowed; /* Change cursor to indicate it's not clickable */
}

.home-page__vote-button.shake {
    animation: shake 0.5s;
}

@keyframes shake {
    0%, 100% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-5px);
    }
    50% {
        transform: translateX(5px);
    }
    75% {
        transform: translateX(-5px);
    }
}

/* Base style and size of link placeholder */
.home-page__deal-link-button, .home-page__deal-link-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 1.2vw;
    font-size: 1rem;
    cursor: pointer;
    padding: 0;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition */
}

/* If link available - display button */
.home-page__deal-link-button {
    background-color: var(--app-red);
    border: none; /* To erase the black border of regular button */
    color: white;
    text-decoration: none;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.home-page__deal-link-button:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); /* Darker and larger shadow on hover */
    transform: translateY(-4px); /* Slightly raise the button */
}

.home-page__deal-link-button img {
    margin-right: 0.5rem;
    height: 1rem;
}

/* If link not available - Hide interface */
.home-page__deal-link-placeholder {
    background-color: transparent;
}

/************************** Button to go on the top of the endless scroll **************************/

/* Back to Top Button */
/* Back to Top Button */
.back-to-top {
    position: fixed;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.3rem 0.9rem;
    background-color: var(--app-red);
    color: white;
    border: none;
    border-radius: 20px;
    font-weight: bold;
    cursor: pointer;
    z-index: 3;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
    display: flex;
    align-items: center;
}

.back-to-top.show {
    opacity: 1;
    pointer-events: auto;
}

.back-to-top__icon {
    height: 1em; /* Make the image size relative to the text size */
    width: auto; /* Maintain aspect ratio */
    margin-right: 0.2em; /* Space between the image and text */
    filter: invert(100%); /* This works for simple black SVGs */
}
/*_____________________________________________________*/

/* Media query for tablets (768px width to 1199px width) */
@media (max-width: 360px) {
    /* Style for the user greeting */
    .home-page__user-greeting {
        margin: 5vw 8vw;
        font-size: 2rem;
        color: var(--app-red);
        line-height: 100%;
    }
    
    .home-page__deal-boxes {
        gap: 1vw;
    }
    
    /* Style for the deal boxes */
    .home-page__deal-box {
        width: calc(50% - 0.5vw);
        border-radius: 3.33vw;
    }

    .home-page__regular-price {
        font-size: 0.99rem; /* Decrease by 10% from 1.1rem */
        margin-right: 1.35vw; /* Decrease by 10% from 1.5vw */
    }

    /* Regular Price Currency Symbol */
    .home-page__regular-currency-symbol {
        font-size: 0.63rem; /* Decrease by 10% from 0.7rem */
    }

    /* Current Price in red and larger */
    .home-page__current-price {
        font-size: 1.35rem; /* Decrease by 10% from 1.5rem */
    }

    /* Current Price Currency Symbol */
    .home-page__current-currency-symbol {
        font-size: 1.08rem; /* Decrease by 10% from 1.2rem */
    }

    .home-page__deal-link-button, .home-page__deal-link-placeholder {
        font-size: 0.8rem;
    }
}

/* Media query for tablets (768px width to 1199px width) */
@media (min-width: 500px) {
    .home-page__deal-link-button {
        font-size: 1.2rem;
    }
    .home-page__deal-link-button img {
        height: 1.2rem;
    }
    .home-page__vote-container {
        font-size: 1.5rem;
    }
}

@media (min-width: 600px) {
    .home-page__vote-container {
        font-size: 1.2rem;
    }
    .home-page__deal-link-button {
        font-size: 1rem;
    }
    .home-page__deal-link-button img {
        height: 1rem;
    }
    .home-page__deal-box {
        width: calc(33.333% - 0.44vw);
        border-radius: calc(3.33vw * 0.50);
    }
    .home-page__interaction-box {
        height: 6vw;
    }

}

@media (min-width: 700px) {

    .home-page__user-greeting {
        margin: 25px 60px;
        font-size: 2.5rem;
    }

    .home-page__deal-boxes {
        gap: calc(1vw * 0.66);
    }
    .home-page__views-box {
        border-radius: calc(0.75vw * 0.66);
    }
    .home-page__content {
        padding: 0.2vw 0.66vw 0.66vw 0.66vw;
    }
    .home-page__deal-title {
        font-size: 1.0rem;
        height: 2.5rem;
    }
    /*.home-page__regular-price {
        font-size: 1.5rem;
        height: 1.5rem;
    }
    .home-page__regular-currency-symbol {
        font-size: 1rem;
    }
    .home-page__current-price {
        font-size: 2.25rem;
        height: 2.25rem;
    }
    .home-page__current-currency-symbol {
        font-size: 1.5rem;
    }*/
    .home-page__interaction-box {
        height: 6vw;
        padding: 0 0.45vw 0.45vw 0.45vw;
    }
    .home-page__vote-container {
        border-radius: 0.8vw;
        font-size: 1.4rem;
        margin-right: 0.45vw;
    }
    .home-page__deal-link-button, .home-page__deal-link-placeholder {
        border-radius: 0.8vw;
        font-size: 1.3rem;
    }
    .home-page__deal-link-button img {
        margin-right: 0.5rem;
        height: 1.3rem;
    }
}

@media (min-width: 800px) {
    .home-page__deal-title {
        font-size: 1.1rem;
        height: 2.75rem;
    }
    .home-page__regular-price {
        font-size: 1.21rem; /* Increase by 10% */
    }

    /* Regular Price Currency Symbol */
    .home-page__regular-currency-symbol {
        font-size: 0.77rem; /* Increase by 10% */
    }

    /* Current Price in red and larger */
    .home-page__current-price {
        font-size: 1.65rem; /* Increase by 10% */
    }

    /* Current Price Currency Symbol */
    .home-page__current-currency-symbol {
        font-size: 1.32rem; /* Increase by 10% */
    }
    .home-page__interaction-box {
        height: 5vw;
    }
    .home-page__vote-container {
        border-radius: 0.8vw;
        font-size: 1.3rem;
    }

    .home-page__deal-link-button, .home-page__deal-link-placeholder {
        border-radius: 0.8vw;
        font-size: 1.2rem;
    }
    .home-page__deal-link-button img {
        margin-right: 0.3rem;
        height: 1.2rem;
    }

    .home-page__deal-box {
        width: calc(25% - 0.50vw);
        border-radius: calc(3.33vw * 0.50);
    }
}

/* Media query for desktops (1200px width and above) */
@media (min-width: 1040px) {
    
    .home-page__user-greeting {
        margin: 25px 25px;
        font-size: 3rem;
    }
    
    .home-page__deal-boxes {
        gap: 4px;
    }

    .home-page__deal-box {
        width: calc(25% - 3px);
        border-radius: 15px;
    }
    .home-page__views-box {
        border-radius: 5px;
        padding: 0px 5px;
    }
    .home-page__views-box--top-left {
        top: 5px;
        left: 5px;
    }
    .home-page__views-box--top-left img {
        margin-right: 5px;
    }
    .home-page__views-box--bottom-left {
        bottom: 5px;
        left: 5px;
    }
    .home-page__views-box--bottom-left img {
        margin-right: 5px;
    }
    .home-page__views-box--top-right {
        top: 5px;
        right: 5px;
    }
    .home-page__views-box--top-right img {
        margin-left: 5px;
    }
    .home-page__views-box--bottom-right {
        bottom: 10px;
        right: 0px;
    }
    .home-page__content {
        padding: 1px 5px 5px 5px;
    }

    .home-page__deal-title {
        font-size: 1.331rem; /* Increase by 10% from 1.21rem */
        height: 3.328rem; /* Increase by 10% from 3.025rem */
    }
    .home-page__regular-price {
        font-size: 1.331rem; /* Increase by 10% from 1.21rem */
        margin-right: 0.6rem; /* Increase by 10% from 1.65vw */
    }
    /* Regular Price Currency Symbol */
    .home-page__regular-currency-symbol {
        font-size: 0.847rem; /* Increase by 10% from 0.77rem */
    }
    /* Current Price in red and larger */
    .home-page__current-price {
        font-size: 1.815rem; /* Increase by 10% from 1.65rem */
    }
    /* Current Price Currency Symbol */
    .home-page__current-currency-symbol {
        font-size: 1.452rem; /* Increase by 10% from 1.32rem */
    }
    
    .home-page__interaction-box {
        height: 50px;
        padding: 0 4px 4px 4px;
    }
    .home-page__vote-container {
        border-radius: 8px;
        font-size: 1.4rem;
        margin-right: 4px;
    }
    .home-page__deal-link-button, .home-page__deal-link-placeholder {
        border-radius: 8px;
        font-size: 1.4rem;
    }
    .home-page__deal-link-button img {
        margin-right: 0.5rem;
        height: 1.2rem;
    }
}