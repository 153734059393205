/*DescriptionBox.js*/

.deal-page .description-box__editor {
    min-height: 150px; /* Initial height */
    overflow-y: auto; /* Add scroll if content exceeds max-height */
    margin-bottom: 15px;
    display: flex; /* Ensure children take up space */
    flex-direction: column; /* Stack children vertically */
}

.new-deal .description-box__editor {
    height: 300px; /* Set your desired height */
    margin-bottom: 15px;
}

.description-box__editor .ql-container {
    flex: 1 1 auto; /* Fill the parent height */
    min-height: 0; /* Prevent height collapse in flex layout */
    max-height: 100%; /* Ensure it stays within the parent's max-height */
    overflow-y: auto; /* Allow scrolling if needed */
    display: flex;
    flex-direction: column;
}

.description-box__editor .ql-editor {
    flex: 1 1 auto; /* Fill remaining space inside .ql-container */
    min-height: 100%; /* Ensure the editor content area takes full height */
    padding: 10px; /* Add some padding for text content */
    box-sizing: border-box; /* Include padding in height calculation */
}



@media (max-width: 1199px) {
    .description-box__editor {
        /*height: 300px; /* Set your desired height here */
    }
}