/*global.css*/

/* Define your colors as CSS variables */
:root {
    --app-red: #cc1517; /* Replace with the actual color code for your app's red */
    --app-red-2: #e54d50; /* Replace with the actual color code for your app's red */
    --app-yellow: #ffb600; /* Replace with the actual color code for your app's yellow */
    --app-yellow-2: #ffd772e5; /* Replace with the actual color code for your app's yellow */
    --app-yellow-hover: #cd9300; /* Replace with the actual color code for your app's yellow */
    --app-blue: #5296A5; /* Replace with the actual color code for your app's yellow */
    --app-green: #3dc300; /* Replace with the actual color code for your app's yellow */
}

button {
    transition: background-color 0.3s ease; /* Smooth transition */
}

/* Reset some default styles */
body, h1, p {
    margin: 0;
    padding: 0;
}

/* Style for the overall page */
body {
    background-color: #e9eaed;
    /*font-family: Arial, sans-serif;*/
    font-family: 'BarlowCondensed', Arial, sans-serif; /* Fallback to sans-serif in case the custom font fails to load */
    font-size: 18px;
}

/* Heading Styles */
h2 {
    margin-bottom: 1rem; /* Add consistent margin below h3 */
}

/* Replace design of quill by the design of the page */
.ql-container {
    font-size: inherit; /* Set the font size explicitly for the editor */
    font: inherit; /* Set the font size explicitly for the editor */
}