/* TermsAndConditions.css */

.terms-and-conditions {
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 30px;
    margin: 20px 0;
}

.terms-and-conditions h1 {
    font-size: 2em;
    margin-bottom: 20px;
}

.terms-and-conditions .sub-heading {
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #333;
}

.terms-and-conditions h2 {
    font-size: 1.5em;
    margin-top: 20px;
}

.terms-and-conditions h3 {
    font-size: 1.2em;
    margin-top: 20px;
}

.terms-and-conditions h4 {
    font-size: 1em;
    margin-top: 20px;
}

.terms-and-conditions p {
    margin-top: 10px;
    line-height: 1.5;
}

.terms-and-conditions ul {
    padding-left: 20px;
    list-style-type: disc;
}

.terms-and-conditions ol {
    padding-left: 20px;
    list-style-type: decimal;
}

.terms-and-conditions .important-notice {
    font-weight: bold;
    text-transform: uppercase;
}

.terms-and-conditions .uppercase {
    text-transform: uppercase;
}

@media (min-width: 400px) {
    .terms-and-conditions {
        padding: 40px;
    }
}

@media (min-width: 600px) {
    .terms-and-conditions {
        padding: 60px;
    }
}

@media (min-width: 1040px) {
    .terms-and-conditions {
        padding: 40px 80px;
    }
}