/* fonts.css*/

@font-face {
    font-family: 'BarlowCondensed';
    src: url('./fonts/BarlowCondensed-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BarlowCondensed';
    src: url('./fonts/BarlowCondensed-SemiBold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'BarlowCondensed';
    src: url('./fonts/BarlowCondensed-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
}