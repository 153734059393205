/* LoadingSpinner.css */

.loading-spinner__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10; /* Ensure it covers other content */
}

.loading-spinner__spinner {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: loading-spinner__spin 1s linear infinite;
}

@keyframes loading-spinner__spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}