/* DealPage.css */

/********** Styles for container ***********/

.deal-page {
    margin-top: -15px;
    min-height: calc(100vh + 15px);
    display: flex;
    flex-direction: column;
    padding-bottom: 70px; /* Adjust padding to match the height of the interaction box */
    
    /*Ensure that it's always hidden by the login modal*/
    position: relative; /* Ensure stacking context */
    z-index: 1; /* Lower than the modal */
}

.deal-page__image-container {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
    aspect-ratio: 1 / 1;
}

.deal-page__deal-box {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
    border: 1px solid #ddd;
    min-height: calc(100vh - 51px);
    flex-grow: 1; /* Ensure the deal box takes up remaining space */
    border-radius: 25px;
    position: relative;
    margin: 0px 0px 4px 0px;
}




/********** Image carousel design ***********/

/* Custom Styles for Carousel Indicators (Dots) */
.carousel-indicators button {
    background-color: #EAD2AC;
    width: 10px;
}

.carousel-indicators .active {
    background-color: #e54d50;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    filter: invert(59%) sepia(91%) saturate(6482%) hue-rotate(348deg) brightness(100%) contrast(100%);
    top: 50%;
    transform: translateY(-50%);
}

.carousel-indicators {
    bottom: 20px;
    z-index: 1;
    position: absolute;
}

/********** Summary box - ViewBox and Name ***********/
.deal-page__summary {
    position: relative;
    margin-top: -25px;
    padding: 5px 5px 35px 5px;
    height: auto;
    min-height: 200px;
    background-color: #ffffff;
    border: 2px solid var(--app-red);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    display: flex;
    flex-direction: column;
}

.deal-page__view-name-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.deal-page__view-box {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 10px 0px 10px; /*Erase padding at the bottom with prices*/
    gap: 5px;
}

.deal-page__view-item {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 5px;
    padding: 5px 7% 5px 7%;
    background-color: var(--app-yellow-2);
    border-radius: 10px;
    /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);*/
}

.deal-page__view-item img,
.deal-page__view-item-text {
    filter: invert(28%) sepia(87%) saturate(4313%) hue-rotate(344deg) brightness(77%) contrast(111%);
}

.deal-page__summary-icon {
    width: 24px; /* Or whatever size the other icons are */
    height: auto; /* Ensure it maintains aspect ratio */
}

.deal-page__view-item-text {
    flex-grow: 1;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.deal-page__name-box {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Ensure the title stays at the top */
    padding: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5; /* Limit to 5 lines */
    -webkit-box-orient: vertical;
    line-height: 1.1; /* Decrease the line spacing */
    white-space: normal; /* Ensure normal line breaking */
}

/* Deal added x days ago */
.deal-page__date-registering-box {
    width: 100%;
    text-align: Left;
    font-size: 1rem;
    color: #999;
    margin-bottom: 10px; /* Adjust spacing as needed */
}

.deal-page__title {
    font-size: 1.3em;
    font-weight: bold;
}

.deal-page__promo-code {
    font-size: 1.2rem;
    color: var(--app-red);
    margin-top: auto; /* Pushes the promo code to the bottom */
    text-align: center; /* Center the promo code */
    display: flex; /* Use flexbox to align items */
    justify-content: center; /* Center the text horizontally */
    align-items: center; /* Center the text vertically */
    cursor: pointer; /* Add a pointer cursor to indicate it is clickable */
    flex-wrap: wrap; /* Allow wrapping if the text is too long */
}

.deal-page__promo-code-value {
    font-weight: bold; /* Make the promo code value bold */
    font-size: 1.5rem;
    margin-left: 0.3rem;
}

/********** Summary box - Price display ***********/

.deal-page__price-box {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    padding: 5px; /* Remove top padding */
    flex-wrap: wrap; /* Allow wrapping */
}

.deal-page__regular-price {
    display: flex;
    align-items: flex-end; /* Align regular price to the bottom */
    font-size: 1.5rem;
    color: #666;
    text-decoration: line-through; /* Regular price style */
    margin-right: 10px; /* Ensure it stays on the left */
    white-space: nowrap; /* Prevent line break */
}

.deal-page__current-price-container {
    display: flex;
    align-items: center; /* Center-align the current price and reduction */
    flex-wrap: wrap; /* Allow wrapping */
    justify-content: flex-start; /* Align items to the left */
    white-space: nowrap; /* Prevent line break initially */
}

.deal-page__current-price {
    color: var(--app-red); /* Use the same color as the homepage */
    text-decoration: none; /* Current price style */
    font-weight: bold;
    font-size: 3rem;
    line-height: 1; /* Ensure the line height matches the font size */
    display: flex;
    margin-right: 10px; /* Adjust spacing as needed */
    align-items: flex-end; /* Align regular price to the bottom */
}

.deal-page__currency-symbol-regular-price {
    font-size: 1rem;
}

.deal-page__currency-symbol-current-price {
    font-size: 1.5rem;
}

.deal-page__reduction {
    background-color: var(--app-yellow);
    color: var(--app-red);
    font-weight: bold;
    padding: 0px 5px;
    border-radius: 10px;
    display: flex;
    align-items: center; /* Center-align text */
}

/********** Summary box - Interaction box ***********/

.deal-page__interaction-box {
    display: flex;
    align-items: stretch; /* Make children elements stretch to the height of the interaction box */
    justify-content: space-between; /* Adjust to ensure proper spacing */
    padding: 10px;
    font-size: 1.7rem;
    height: 70px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 4; /* Ensure it appears above other elements */
    background-color: #fff; /* Adjust background color to match design */
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.3); /* Add shadow to make it stand out */
}

.deal-page__vote-container,
.deal-page__deal-link-button, 
.deal-page__deal-link-placeholder,
.deal-page__share-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-sizing: border-box;
    margin-right: 1.5vw;
}

.deal-page__vote-container,
.deal-page__deal-link-button {
    box-shadow: inset 0 0 0 2px var(--app-red);
}

.deal-page__vote-container,
.deal-page__deal-link-button, 
.deal-page__deal-link-placeholder {
   width: 100%;
   margin-right: 2vw;
}

.deal-page__vote-section {
    width: 33.33%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--app-red);
    font-weight: bold;
}

.deal-page__vote-button {
    background: none;
    border: none;
    margin: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition */
}

/* Apply the filter only to the thumb up icon */
.deal-page__vote-button.thumb-up img {
    filter: invert(75%) sepia(55%) saturate(7018%) hue-rotate(65deg) brightness(105%) contrast(105%);
}

.deal-page__vote-button:hover {
    transform: translateY(-3px); /* Slightly raise the button */
}

.deal-page__vote-positive-count {
    color: var(--app-green);
}

.deal-page__vote-button.shake {
    animation: shake 0.5s;
}

/* Cancel hover when the button is already validated*/
.deal-page__vote-button.disabled:hover {
    transform: none; /* Cancel hover effect */
    cursor: not-allowed; /* Change cursor to indicate it's not clickable */
}

@keyframes shake {
    0%, 100% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-5px);
    }
    50% {
        transform: translateX(5px);
    }
    75% {
        transform: translateX(-5px);
    }
}

.deal-page__deal-link-button {
    background-color: var(--app-red);
    color: white;
    text-decoration: none;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition */
}

.deal-page__deal-link-button:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); /* Darker and larger shadow on hover */
    transform: translateY(-4px); /* Slightly raise the button */
}

.deal-page__deal-link-button img, .deal-page__vote-button img {
    height: 1em;
}

.deal-page__deal-link-button img {
    margin-right: 0.2em;
}

.deal-page__deal-link-placeholder {
    background-color: transparent;
    border: none;
}

.deal-page__share-box {
    cursor: pointer;
    margin-right: 0px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition */
}

.deal-page__share-box img {
    height: 100%;
}

.deal-page__share-box:hover {
    transform: translateY(-4px); /* Slightly raise the button */
}

/********** Description box ***********/
.deal-page__description {
    margin-top: -25px;
    padding: 15px;
    height: auto;
    min-height: 250px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border: 2px solid var(--app-red);
    border-radius: 25px;
    position: relative;
    font-size: 1.2rem;
    flex-grow: 1; /* Ensure description takes up remaining space */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure the content within description is spaced properly */
}

/* Modify First Description Box */
.deal-page__description--first {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: calc(15px + 25px);
  }

.deal-page__description-buttons {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
}

.deal-page__description-button {
    background-color: #ff4d4d; /* Adjust as needed */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.deal-page__description-button:hover {
    background-color: #cc0000; /* Adjust as needed */
}

/********** Placeholder for no image ***********/
.deal-page__no-image-placeholder {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
}

/********** Message box ***********/
  
  /********** Send Button **********/
.deal-page__send-button {
    background-color: var(--app-red);
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    align-self: flex-end;
    height: 40px;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 100px;
    transition: background-color 0.3s ease;
    margin-right: 0.7rem;
    overflow: hidden;
    margin-top: -65px;
    z-index: 1;
}
  
.deal-page__send-button:hover {
    background-color: #a90000;
}
  
/********** Comments Section **********/
.deal-page__comments {
    margin-top: 10px;
}

.deal-page__comment {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.deal-page__comment:last-child {
    border-bottom: none;
}

.deal-page__comment-alias {
    color: var(--app-red);
    font-weight: bold;
}

.deal-page__comment-date {
    font-size: 1rem;
    color: #999;
    margin-left: 10px;
}

.deal-page__comment p {
    color: #333;
}

/********** Reply Section **********/
.deal-page__reply {
    margin-top: 10px;
    margin-left: 20px;
    padding-left: 15px;
    border-left: 2px solid #ddd;
    font-size: 1.1rem;
}

.deal-page__reply-alias {
    color: var(--app-red);
    font-weight: bold;
}

.deal-page__reply-date {
    font-size: 0.9rem;
    color: #777;
    margin-left: 10px;
}

.deal-page__reply p {
    margin: 5px 0 0 0;
    color: #333;
}

.deal-page__reply-button {
    background-color: transparent;
    border: none;
    color: var(--app-red);
    cursor: pointer;
    margin-top: 5px;
    font-size: 1rem;
}

/* Align reply form as a child comment */
.deal-page__reply-box {
    margin-left: 20px; /* Align nested replies */
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 15px;
    border-left: 2px solid #ddd; /* Match child comment style */
    display: flex;
    flex-direction: column;
}

.deal-page__send-reply-button {
    background-color: var(--app-red);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 100px;
    cursor: pointer;
    align-self: flex-end; /* Align button to the right */
    transition: background-color 0.3s ease;
    margin-top: -65px; /* Align similar to main send button */
    z-index: 1;
}

.deal-page__send-reply-button:hover {
    background-color: #a90000; /* Darker red on hover */
}

/* Specific styles for the hide button */
.deal-page__reply-box .deal-page__reply-button {
    align-self: flex-start; /* Align the button to the left */
    margin-top: 10px; /* Add some spacing above the button */
    background-color: transparent;
    border: none;
    color: var(--app-red);
    cursor: pointer;
}

.error-text {
    color: red;
    font-size: 1rem;
    margin-top: 8px;
}

.no-comments-message {
    margin: 25px 15px 15px 15px; /* Adjust the value as needed */
}

/*_________ Message box _________*/


/********** Styles for expired deal ***********/
.deal-page__expired-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    text-align: center;
    padding: 5px;
    padding-top: 25px;
    font-weight: bold;
    z-index: 5;
}

.deal-page__expired-content {
    filter: grayscale(80%);
    position: relative;
}

.deal-page__expired-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    border-radius: 15px;
}

/* Media query for very small screens (max-width: 360px) */
@media (max-width: 360px) {
    .deal-page {
        margin-top: -15px;
        min-height: calc(100vh + 15px);
        display: flex;
        flex-direction: column;
        padding-bottom: 50px; /* Adjust padding to match the height of the interaction box */
    }

    .deal-page__name-box {
        padding: 5px;
    }

    .deal-page__view-box {
        padding: 5px 5px 0px 5px; /*Erase padding at the bottom with prices*/
        font-size: 1rem;
    }

    .deal-page__interaction-box {
        padding: 5px;
        font-size: 1.5rem;
        height: 50px;
    }

    .deal-page__deal-link-button img, .deal-page__vote-button img {
        height: 0.8em;
    }

    .deal-page__vote-container,
    .deal-page__deal-link-button, 
    .deal-page__deal-link-placeholder,
    .deal-page__share-box {
        font-size: 1.5rem;
    }

    .deal-page__description {
        font-size: 1.1rem;
    }
}

@media (min-width: 700px) {
    
    .deal-page {
        padding-bottom: 0; /* Remove extra bottom padding */
      }
    
    .deal-page__deal-box {
        display: flex; /* Enable flexbox layout */
        flex-direction: column; /* Set column direction for main container */
        align-items: flex-start;
        
    }

    .deal-page__main-content {
        display: flex; /* Enable flexbox layout */
        flex-direction: row; /* Set row direction for image and summary container */
        width: 100%; /* Ensure full width */
        align-items: stretch; /* Make children elements stretch to the same height */
    }

    .deal-page__image-container {
        width: 50%; /* Image container takes half the viewport width */
        margin: 0;
    }

    .deal-page__summary {
        width: 50%; /* Summary takes the other half */
        padding: 10px;
        padding-top: 35px;
        padding-bottom: 35px;
        border: 0;
        margin: 0; /* Cancel margin top of smartphone interface */
        display: flex; /* Enable flexbox layout */
        flex-direction: column; /* Ensure the content within summary is column aligned */
        justify-content: space-between; /* Ensure content within the summary is spaced properly */
    }

    .deal-page__name-box {
        display: flex; /* Necessary otherwise the title taked the display of smartphone size */
        line-height: 1.1; /* Decrease the line spacing */
        max-height: none; /* Ensure the box height is restricted to 5 lines */
    }

    .deal-page__description {
        width: 100%; /* Description takes the full width below */
        margin-top: -25px; /* Space between summary and description */
    }

    .deal-page__view-box {
        padding: 0px 10px 0px 0px; /*Erase padding at the bottom with prices*/
    }

    .deal-page__name-box {
        padding: 0; /*Erase padding at the bottom with prices*/
    }

    .deal-page__interaction-box {
        display: flex;
        align-items: stretch; /* Make children elements stretch to the height of the interaction box */
        justify-content: space-between; /* Adjust to ensure proper spacing */
        padding: 10px 0px 0px 0px;
        font-size: 1.6rem;
        position: initial; /* Reset position */
        bottom: initial; /* Reset bottom */
        left: initial; /* Reset left */
        width: initial; /* Reset width */
        z-index: initial; /* Reset z-index */
        background-color: initial; /* Reset background color */
        box-shadow: none; /* Remove shadow */
    }

    .deal-page__description {
        padding: 25px 25px 40px 25px;
    }
}

@media (min-width: 900px) {
    .deal-page__description {
        padding: 50px 50px 40px 50px;
    }

}

@media (min-width: 1040px) {

    .deal-page__summary {
        padding: 20px;
        padding-top: 45px;
        padding-bottom: 45px;
        border: 0;
    }

    .deal-page__name-box {
        display: flex; /* Necessary otherwise the title taked the display of smartphone size */
        line-height: 1.1; /* Decrease the line spacing */
        max-height: none; /* Ensure the box height is restricted to 5 lines */
    }

    .deal-page__description {
        width: 100%; /* Description takes the full width below */
        margin-top: -25px; /* Space between summary and description */
    }

    .deal-page__view-box {
        padding-right: 20px; /*Erase padding at the bottom with prices*/
    }

    .deal-page__name-box {
        padding: 0; /*Erase padding at the bottom with prices*/
    }

    .deal-page__interaction-box {
        padding: 0px;
        height: 50px;
    }
}