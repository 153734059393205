/*CategoryHeader.css*/


.category-header {
    position: relative;
    width: 100%; /* Full width of the screen */
    height: 115px; /* Fixed height */
    
    /*Margin below element above*/
    margin-top: -25px;
    padding-top: 15px;
    margin-bottom: 10px;

    background-color: white; /* Black background */
    display: flex;
    justify-content: center; /* Center the inner content horizontally */
    align-items: center; /* Center the inner content vertically */
    border-bottom-left-radius: 25px; /* Adjust the pixel value as needed */
    border-bottom-right-radius: 25px; /* Adjust the pixel value as needed */

    z-index: 1; /* Lower than the header, so it appears behind */
}

.category-header__container {
    width: 100%; /* Use full width */
    max-width: 1200px; /* Max-size of container */
    display: flex;
    align-items: center;
}

.category-header__arrow-box {
    margin: 0.3rem;
}

.category-header__arrow {
    cursor: pointer;
    background: transparent;
    border: none;
    display: flex;
    border-radius: 50%; /* Round shape for hover background */
    transition: all var(--transition-duration) ease; /* Smooth transition for size and background */
}

.category-header__arrow img {
    width: 1rem; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
    transition: transform var(--transition-duration) ease;
}

/* Hover effect for arrows */
.category-header__arrow:hover {
    background-color: #ddd; /* Grey background */
    padding: 0.1rem;
}

.category-header__categories-container {
    display: flex;
    overflow-x: auto;
    flex-grow: 1; /* Take up remaining space */
    white-space: nowrap; /* Prevent text wrapping */
    scrollbar-width: none;
    -ms-overflow-style: none;
    transition: scroll-behavior var(--transition-duration) ease; /* Use CSS variable */
}

.category-header__categories-container::-webkit-scrollbar {
    display: none;
}

.category-header__arrow {
    cursor: pointer;
}

.category-header__arrow:hover img {
    transform: scale(1.25); /* Enlarge the arrow icon */
}

.category-header__category-button {
    background: none;
    border: none; /* Black border around each button */
    cursor: pointer;
    transition: all var(--transition-duration) ease; /* Use CSS variable */
    display: flex; /* Enable flexbox layout */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    max-width: 4.5rem; /* Set the width to 80px */
    min-width: 4.5rem; /* Set the width to 80px */
    height: 5rem;
    padding-top: 5px;
}

.category-header__category-text {
    white-space: normal; /* Allow wrapping */
    overflow: hidden; /* Hide text that overflows */
    text-overflow: ellipsis; /* Add ellipsis to text that overflows */
    line-height: 0.9rem; /* Adjust line height as needed */
    font-size: 0.8rem; /* Adjust font size as needed */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Limit to two lines */
}

.category-header__category-button:hover {
    background-color: #f0f0f0; /* Change background color on hover */
    transition: background-color var(--transition-duration) ease; /* Smooth transition for background color */
    border-radius: 1rem; /* Increase border-radius on hover */
}

.category-header__category-button--selected {
    background-color: #d3d3d3; /* Or any other color for the selected category */
    color: #000; /* Adjust text color if needed */
}

.category-header__category-icon {
    margin-bottom: 0.1rem; /* Add space below the icon */
    width: 2.5rem; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
    margin-top: 0; /* Adjust top margin to set fixed position */
}

@media (min-width: 500px) {

}

@media (min-width: 700px) {
    .category-header {
        height: 138px; /* Fixed height */
        border-bottom-left-radius: 25px; /* Adjust the pixel value as needed */
        border-bottom-right-radius: 25px; /* Adjust the pixel value as needed */
        margin-top: -30px;
        padding-top: 30px;
    }
    .category-header__category-button {
        max-width: 5.4rem; /* Increased from 4.5rem */
        min-width: 5.4rem; /* Increased from 4.5rem */
        height: 6rem; /* Increased from 5rem */
    }
    .category-header__category-text {
        line-height: 1.08rem; /* Increased from 0.9rem */
        font-size: 0.96rem; /* Increased from 0.8rem */
    }
    .category-header__category-icon {
        margin-bottom: 0.12rem; /* Increased from 0.1rem */
        width: 3rem; /* Increased from 2.5rem */
        height: auto;
    }
    .category-header__arrow img {
        width: 1.2rem; /* Increased from 1rem */
    }
    .category-header__arrow:hover {
        padding: 1.2rem; /* Increased from 1vw */
    }
}

@media (min-width: 900px) {

}