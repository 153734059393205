/*Admin.css*/

.admin__deal-box {
    display: flex;
    align-items: flex-start;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
    overflow: hidden;
}

.admin__deal-box-description {
    width: 60%;
}

.admin__deal-box-image {
    width: 100px;
    border-radius: 5px 5px 0 0;
}

.admin__deal-box-controls {
    margin-top: 10px;
}

.admin__deal-box-controls button {
    margin-right: 10px;
}

.admin__deal-box-report {
    width: 40%;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    margin-left: 10px;
}

.admin__deal-box-report {
    margin-top: 20px;
}

.admin__report-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
}

.admin__report-table th,
.admin__report-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.admin__report-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.admin__view-reports-button {
    background-color: var(--app-red);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.admin__view-reports-button:hover {
    background-color: darkred;
}