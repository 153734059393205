/* Container.css */


/* Container styles */
.container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 60px 1vw 1vw 1vw;
    background-color: transparent; /* Make background transparent */
    box-shadow: none; /* Remove the shadow */
    border: none; /* Remove any borders */
}

/* Modifier for marginless container on small screens */
.container--marginless-on-small {
    margin: 0 auto;
    padding: 60px 0px 0px 0px;
    background-color: transparent; /* Make background transparent */
    box-shadow: none; /* Remove the shadow */
    border: none; /* Remove any borders */
}

/* Modifier for header container */
.container--header {
    padding: 0;
}

@media (min-width: 700px) {
    
    /*.container--header {
        padding: 0;
    }*/
}

@media (min-width: 1040px) {
    .container {
        padding: 60px 0px 0px 0px;
    }
    .container--header {
        padding: 0;
    }
}